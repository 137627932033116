.nb-theme-default,
.nb-theme-dark {
    nb-card-header {
        h1 {
            font-family: Open Sans, sans-serif;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
        }
    }
}
