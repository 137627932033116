.input-icon-append {
    border-color: #28a745;
    padding-right: 30px !important;
    padding-right: calc(1.5em + 0.75rem);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

    &.icon-chevron {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><g data-name='Layer 2'><g data-name='arrow-ios-downward'><rect width='24' height='24' opacity='0'/><path d='M12 16a1 1 0 0 1-.64-.23l-6-5a1 1 0 1 1 1.28-1.54L12 13.71l5.36-4.32a1 1 0 0 1 1.41.15 1 1 0 0 1-.14 1.46l-6 4.83A1 1 0 0 1 12 16z'/></g></g></svg>");
    }
}

[nbInput].ng-touched.ng-invalid:not(:focus) {
    border-color: red;
}
