// margin
.m-0 {
    margin: 0 !important;
}

.m-5 {
    @include media-breakpoint-up(lg) {
        margin: space(5) !important;
    }
}
.m-10 {
    @include media-breakpoint-up(lg) {
        margin: space(10) !important;
    }
}
.m-15 {
    @include media-breakpoint-up(lg) {
        margin: space(15) !important;
    }
}

.m-20 {
    @include media-breakpoint-up(lg) {
        margin: space(20) !important;
    }
}

.m-25 {
    @include media-breakpoint-up(lg) {
        margin: space(25) !important;
    }
}

.m-30 {
    @include media-breakpoint-up(lg) {
        margin: space(30) !important;
    }
}

.m-35 {
    @include media-breakpoint-up(lg) {
        margin: space(35) !important;
    }
}
.m-40 {
    @include media-breakpoint-up(lg) {
        margin: space(40) !important;
    }
}

.m-45 {
    @include media-breakpoint-up(lg) {
        margin: space(45) !important;
    }
}

.m-50 {
    @include media-breakpoint-up(lg) {
        margin: space(50) !important;
    }
}

// margin top and bottom
.my-5 {
    margin-top: space(5) !important;
    margin-bottom: space(5) !important;
}
.my-10 {
    margin-top: space(10) !important;
    margin-bottom: space(10) !important;
}

.my-15 {
    margin-top: space(15) !important;
    margin-bottom: space(15) !important;
}

.my-20 {
    margin-top: space(20) !important;
    margin-bottom: space(20) !important;
}

.my-25 {
    margin-top: space(25) !important;
    margin-bottom: space(25) !important;
}

.my-30 {
    margin-top: space(30) !important;
    margin-bottom: space(30) !important;
}

.my-35 {
    margin-top: space(35) !important;
    margin-bottom: space(35) !important;
}

.my-40 {
    margin-top: space(40) !important;
    margin-bottom: space(40) !important;
}

.my-45 {
    margin-top: space(45) !important;
    margin-bottom: space(45) !important;
}

.my-50 {
    margin-top: space(50) !important;
    margin-bottom: space(50) !important;
}

// margin left and right
.mx-5 {
    margin-left: space(5) !important;
    margin-right: space(5) !important;
}

.mx-10 {
    margin-left: space(10) !important;
    margin-right: space(10) !important;
}

.mx-15 {
    margin-left: space(15) !important;
    margin-right: space(15) !important;
}

.mx-20 {
    margin-left: space(20) !important;
    margin-right: space(20) !important;
}

.mx-25 {
    margin-left: space(25) !important;
    margin-right: space(25) !important;
}

.mx-30 {
    margin-left: space(30) !important;
    margin-right: space(30) !important;
}

.mx-35 {
    margin-left: space(35) !important;
    margin-right: space(35) !important;
}

.mx-40 {
    margin-left: space(40) !important;
    margin-right: space(40) !important;
}

.mx-45 {
    margin-left: space(45) !important;
    margin-right: space(45) !important;
}

.mx-50 {
    margin-left: space(50) !important;
    margin-right: space(50) !important;
}

// margin top
.mt-5 {
    margin-top: space(5) !important;
}

.mt-10 {
    margin-top: space(10) !important;
}

.mt-15 {
    margin-top: space(15) !important;
}

.mt-20 {
    margin-top: space(20) !important;
}

.mt-25 {
    margin-top: space(25) !important;
}

.mt-30 {
    margin-top: space(30) !important;
}

.mt-35 {
    margin-top: space(35) !important;
}

.mt-40 {
    margin-top: space(40) !important;
}

.mt-45 {
    margin-top: space(45) !important;
}

.mt-50 {
    margin-top: space(50) !important;
}

// Margin bottom
.mb-5 {
    margin-bottom: space(5) !important;
}

.mb-10 {
    margin-bottom: space(10) !important;
}

.mb-15 {
    margin-bottom: space(15) !important;
}

.mb-20 {
    margin-bottom: space(20) !important;
}

.mb-25 {
    margin-bottom: space(25) !important;
}

.mb-30 {
    margin-bottom: space(30) !important;
}

.mb-35 {
    margin-bottom: space(35) !important;
}

.mb-40 {
    margin-bottom: space(40) !important;
}

.mb-45 {
    margin-bottom: space(45) !important;
}

.mb-50 {
    margin-bottom: space(50) !important;
}

// Margin right
.mr-5 {
    margin-right: space(5) !important;
}

.mr-10 {
    margin-right: space(10) !important;
}

.mr-15 {
    margin-right: space(15) !important;
}

.mr-20 {
    margin-right: space(20) !important;
}

.mr-25 {
    margin-right: space(25) !important;
}

.mr-30 {
    margin-right: space(30) !important;
}

.mr-35 {
    margin-right: space(35) !important;
}

.mr-40 {
    margin-right: space(40) !important;
}

.mr-45 {
    margin-right: space(45) !important;
}

.mr-50 {
    margin-right: space(50) !important;
}

// Margin left
.ml-5 {
    margin-left: space(5) !important;
}

.ml-10 {
    margin-left: space(10) !important;
}

.ml-15 {
    margin-left: space(15) !important;
}

.ml-20 {
    margin-left: space(20) !important;
}

.ml-25 {
    margin-left: space(25) !important;
}

.ml-30 {
    margin-left: space(30) !important;
}

.ml-35 {
    margin-left: space(35) !important;
}

.ml-40 {
    margin-left: space(40) !important;
}

.ml-45 {
    margin-left: space(45) !important;
}

.ml-50 {
    margin-left: space(50) !important;
}
