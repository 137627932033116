.a-shadow-fixed-sm {
    box-shadow: $shadow-sm;
}
.a-shadow-fixed-xs {
    box-shadow: $shadow-xs;
}
.a-shadow-fixed-md {
    box-shadow: $shadow-md;
}

.a-shadow-fixed-lg {
}

.a-shadow-hover {
}

.a-shadow-hover-md-animated-up {
    box-shadow: $shadow-md;

    @include media-breakpoint-up(lg) {
        &:hover {
            box-shadow: $shadow-sm;
        }
    }
}
