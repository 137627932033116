.drag-list {
    width: 100%;
    max-width: 100%;
    min-height: 60px;
    display: block;
    border-radius: 4px;
    overflow: hidden;
}

.drag-box {
    position: relative;
    padding: 20px 10px;
    border-bottom: dashed 1px #bbb;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: move;
    background: white;

    .box-actions {
        position: absolute;
        right: 20px;
        font-size: 20px;

        .action {
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.cdk-drag-preview {
    box-shadow: $shadow-md;
}

.cdk-drag-placeholder {
    opacity: 0;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-box:last-child {
    border: none;
}

.drag-list.cdk-drop-list-dragging .drag-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
