@mixin ngx-layout() {
    @include media-breakpoint-down(is) {
        .row {
            margin-left: -10px;
            margin-right: -10px;
        }
        .mat-dialog-content {
            .row {
                margin-left: -15px;
                margin-right: -15px;
            }
        }
    }
}
