// padding
.p-5 {
    padding: space(5) !important;
}

.p-10 {
    padding: space(10) !important;
}

.p-15 {
    padding: space(15) !important;
}

.p-20 {
    padding: space(20) !important;
}

.p-25 {
    padding: space(25) !important;
}

.p-30 {
    padding: space(30) !important;
}

.p-35 {
    padding: space(35) !important;
}

.p-40 {
    padding: space(40) !important;
}

.p-45 {
    padding: space(45) !important;
}

.p-50 {
    padding: space(50) !important;
}

// padding top and bottom
.py-5 {
    padding-top: space(5) !important;
    padding-bottom: space(5) !important;
}

.py-10 {
    padding-top: space(10) !important;
    padding-bottom: space(10) !important;
}

.py-15 {
    padding-top: space(15) !important;
    padding-bottom: space(15) !important;
}

.py-20 {
    padding-top: space(20) !important;
    padding-bottom: space(20) !important;
}

.py-25 {
    padding-top: space(25) !important;
    padding-bottom: space(25) !important;
}

.py-30 {
    padding-top: space(30) !important;
    padding-bottom: space(30) !important;
}

.py-35 {
    padding-top: space(35) !important;
    padding-bottom: space(35) !important;
}

.py-40 {
    padding-top: space(40) !important;
    padding-bottom: space(40) !important;
}

.py-45 {
    padding-top: space(45) !important;
    padding-bottom: space(45) !important;
}

.py-50 {
    padding-top: space(50) !important;
    padding-bottom: space(50) !important;
}

// padding left and right
.px-5 {
    padding-left: space(5) !important;
    padding-right: space(5) !important;
}

.px-10 {
    padding-left: space(10) !important;
    padding-right: space(10) !important;
}

.px-15 {
    padding-left: space(15) !important;
    padding-right: space(15) !important;
}

.px-20 {
    padding-left: space(20) !important;
    padding-right: space(20) !important;
}

.px-25 {
    padding-left: space(25) !important;
    padding-right: space(25) !important;
}

.px-30 {
    padding-left: space(30) !important;
    padding-right: space(30) !important;
}

.px-35 {
    padding-left: space(35) !important;
    padding-right: space(35) !important;
}

.px-40 {
    padding-left: space(40) !important;
    padding-right: space(40) !important;
}

.px-45 {
    padding-left: space(45) !important;
    padding-right: space(45) !important;
}

.px-50 {
    padding-left: space(50) !important;
    padding-right: space(50) !important;
}

// padding top
.pt-5 {
    padding-top: space(5) !important;
}

.pt-10 {
    padding-top: space(10) !important;
}

.pt-15 {
    padding-top: space(15) !important;
}

.pt-20 {
    padding-top: space(20) !important;
}

.pt-25 {
    padding-top: space(25) !important;
}

.pt-30 {
    padding-top: space(30) !important;
}

.pt-35 {
    padding-top: space(35) !important;
}

.pt-40 {
    padding-top: space(40) !important;
}

.pt-45 {
    padding-top: space(45) !important;
}

.pt-50 {
    padding-top: space(50) !important;
}

// padding bottom
.pb-5 {
    padding-bottom: space(5) !important;
}

.pb-10 {
    padding-bottom: space(10) !important;
}

.pb-15 {
    padding-bottom: space(15) !important;
}

.pb-20 {
    padding-bottom: space(20) !important;
}

.pb-25 {
    padding-bottom: space(25) !important;
}

.pb-30 {
    padding-bottom: space(30) !important;
}

.pb-35 {
    padding-bottom: space(35) !important;
}

.pb-40 {
    padding-bottom: space(40) !important;
}

.pb-45 {
    padding-bottom: space(45) !important;
}

.pb-50 {
    padding-bottom: space(50) !important;
}

// padding right
.pr-5 {
    padding-right: space(5) !important;
}

.pr-10 {
    padding-right: space(10) !important;
}

.pr-15 {
    padding-right: space(15) !important;
}

.pr-20 {
    padding-right: space(20) !important;
}

.pr-25 {
    padding-right: space(25) !important;
}

.pr-30 {
    padding-right: space(30) !important;
}

.pr-35 {
    padding-right: space(35) !important;
}

.pr-40 {
    padding-right: space(40) !important;
}

.pr-45 {
    padding-right: space(45) !important;
}

.pr-50 {
    padding-right: space(50) !important;
}

// padding left
.pl-5 {
    padding-left: space(5) !important;
}

.pl-10 {
    padding-left: space(10) !important;
}

.pl-15 {
    padding-left: space(15) !important;
}

.pl-20 {
    padding-left: space(20) !important;
}

.pl-25 {
    padding-left: space(25) !important;
}

.pl-30 {
    padding-left: space(30) !important;
}

.pl-35 {
    padding-left: space(35) !important;
}

.pl-40 {
    padding-left: space(40) !important;
}

.pl-45 {
    padding-left: space(45) !important;
}

.pl-50 {
    padding-left: space(50) !important;
}
