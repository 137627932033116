@import './themes';

@mixin nb-overrides() {
    .columns {
        max-width: 1920px;
    }

    label {
        cursor: pointer;
    }

    nb-select.size-medium button {
        padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

        nb-icon {
            right: 0.41rem !important;
        }
    }

    .table {
        color: inherit;
    }

    .mat-dialog-container {
        background: nb-theme(background-basic-color-1);
        color: nb-theme(text-basic-color);
    }

    .toggle.checked .text {
        color: nb-theme(color-success-500);
    }

    .cdk-overlay-container,
    .cdk-overlay-pane {
        z-index: 3000 !important;
    }

    @include media-breakpoint-down(sm) {
        .cdk-overlay-container,
        .cdk-overlay-pane {
            z-index: 3000 !important;
        }
    }

    nb-layout.colored-layout-white .layout {
        background-color: #fff;
    }

    nb-layout.fullWidth .layout .layout-container .content {
        width: 100%;
        flex: 0 100 100% !important;
        .columns {
            max-width: none !important;
        }
    }

    .custom-dialog {
        border-radius: 8px;
        max-width: 480px;

        h1 {
            font-family: 'Futura PT', sans-serif;
            font-size: 1.25rem;
        }

        nb-card-header {
            border-bottom: none;
            padding-bottom: 0;

            nb-icon {
                font-size: 1.75rem;
            }
        }

        nb-card-footer {
            border-top: none;
            padding-top: 0;
        }
    }
}

$nb-themes: nb-register-theme(
    (
        font-family-secondary: 'Futura PT'
    ),
    default,
    default
);
