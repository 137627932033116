//Spaces
$space-5: 5px !default;
$space-10: 10px !default;
$space-15: 15px !default;
$space-20: 20px !default;
$space-25: 25px !default;
$space-30: 30px !default;
$space-35: 35px !default;
$space-40: 40px !default;
$space-45: 45px !default;
$space-50: 50px !default;
$spaces: (
    5: $space-5,
    10: $space-10,
    15: $space-15,
    20: $space-20,
    25: $space-25,
    30: $space-30,
    35: $space-35,
    40: $space-40,
    45: $space-45,
    50: $space-50
) !default;

// Shadows
$shadow-xs: 0px 2px 6px rgba(0, 0, 0, 0.06);
$shadow-sm: 0px 5px 30px rgba(0, 0, 0, 0.06);
$shadow-md: 0px 5px 60px rgba(0, 0, 0, 0.06);
