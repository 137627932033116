@font-face {
    font-family: 'Moret SemiBold';
    src: url('../../../assets/fonts/moret/moret-semibold-webfont.woff2') format('woff2'),
        url('../../../assets/fonts/moret/moret-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Book';
    src: url('../../../assets/fonts/FuturaPT-Book.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPT-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Cond Book';
    src: url('../../../assets/fonts/FuturaPTCond-BookObl.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPTCond-BookObl.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Cond Book';
    src: url('../../../assets/fonts/FuturaPTCond-Book.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPTCond-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url('../../../assets/fonts/FuturaPTCond-BoldObl.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPTCond-BoldObl.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url('../../../assets/fonts/FuturaPTCond-Medium.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPTCond-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url('../../../assets/fonts/FuturaPTCond-Bold.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPTCond-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Cond Extra';
    src: url('../../../assets/fonts/FuturaPTCond-ExtraBoldObl.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPTCond-ExtraBoldObl.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../../../assets/fonts/FuturaPT-BoldObl.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPT-BoldObl.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Book';
    src: url('../../../assets/fonts/FuturaPT-BookObl.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPT-BookObl.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../../../assets/fonts/FuturaPT-Bold.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPT-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Cond Extra';
    src: url('../../../assets/fonts/FuturaPTCond-ExtraBold.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPTCond-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Cond';
    src: url('../../../assets/fonts/FuturaPTCond-MediumObl.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPTCond-MediumObl.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../../../assets/fonts/FuturaPT-Heavy.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPT-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Extra';
    src: url('../../../assets/fonts/FuturaPT-ExtraBold.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPT-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../../../assets/fonts/FuturaPT-Light.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPT-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Demi';
    src: url('../../../assets/fonts/FuturaPT-DemiObl.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPT-DemiObl.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Demi';
    src: url('../../../assets/fonts/FuturaPT-Demi.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPT-Demi.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../../../assets/fonts/FuturaPT-HeavyObl.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPT-HeavyObl.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../../../assets/fonts/FuturaPT-LightObl.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPT-LightObl.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT Extra';
    src: url('../../../assets/fonts/FuturaPT-ExtraBoldObl.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPT-ExtraBoldObl.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../../../assets/fonts/FuturaPT-Medium.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPT-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura PT';
    src: url('../../../assets/fonts/FuturaPT-MediumObl.woff2') format('woff2'),
        url('../../../assets/fonts/FuturaPT-MediumObl.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
