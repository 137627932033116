@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
@import 'fonts.css';

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// loading our custom styling
// Core
@import '../../@styleguide/core/variables';
@import '../../@styleguide/core/functions';

// Fontawesome
@import '@fortawesome/fontawesome-free/css/fontawesome.css';
@import '@fortawesome/fontawesome-free/css/all.css';

// Atoms
@import '../../@styleguide/atoms/shadow';
@import '../../@styleguide/atoms/margins';
@import '../../@styleguide/atoms/paddings';
@import '../../@styleguide/atoms/print';
@import '../../@styleguide/atoms/breadcrumb';
@import '../../@styleguide/atoms/input';

// Molecules
@import '../../@styleguide/molecules/drag-list';
@import '../../@styleguide/molecules/nb-card-header';

// install the framework and custom global styles
@include nb-install() {
    // framework global styles
    @include nb-theme-global();
    @include nb-auth-global();

    @include ngx-layout();
    // loading progress bar
    @include ngx-pace-theme();

    @include nb-overrides();
}
